import React from 'react'
import {StaticImage as Img} from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from './style'
import { Form, Link } from '~components'
import { Images } from '~data'
export default function HeroSection(){
return(
<Hero overlay={Images.Services.heroImgOverlay} style={{backgroundImage: 'url(https://res.cloudinary.com/dv10simqm/image/upload/v1642006027/helios/HOME%20HELIOSDG/bg_home_2x_cb92bi.png)'}} >
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-7 col-xl-7 col-lg-9 col-md-10">
        <Hero.Content>
          <Hero.Title as="h2" fontColor="#fff" className="main_banner_ttl">A GLOBAL TECH & <br /> DIGITAL SOLUTIONS COMPANY</Hero.Title>
          <Hero.Text fontColor="#fff">Déjanos tu correo y en breve te contactaremos.</Hero.Text>
          {/* Newsletter */}
          <Hero.Newsletter mt="40px">
            <Form>
              <Hero.Box>
                <i className="far fa-envelope icon icon_black" />
                <Form.Input placeholder="Correo electrónico"/>
              </Hero.Box>
              <Hero.NewsletterButton rounded={true} className="btn-torch-red btn_main_banner">Enviar</Hero.NewsletterButton>
            </Form>
            {/* <Hero.NewsletterText>Get latest templates right at your inbox</Hero.NewsletterText> */}
          </Hero.Newsletter>
          {/*/ .Newsletter */}
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
</Hero>

)
}