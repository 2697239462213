import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import Integration from "./style"
import { Images } from "~data"
import SectionTitle from './Components/SectionTitle'
const IntegrationSection = ({ ...rest }) => {
  return (
    <Integration
      backgroundColor="#fff"
    >
      <Container>
        <Row className="row justify-content-center tech_stack_container">
          <Col className="col-xl-10">
            <h6 className="helios_subttl text-center">Funciona con tecnologías a gran escala</h6>
            <h2 className="helios_ttl text-center">Tecnología</h2>
            {/* <Integration.Box className="text-center">
              <SectionTitle
                title="Tecnología"
                
              />
              
            </Integration.Box> */}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="12" className="col-xl-5 col-lg-6 col-md-9">
            <div className="brand-image-group brand-image-group--l2">
              <div className="brand-image-group--l2__image--main circle-126">
                <div className="img-0">
                  <img className="img_tree_logo" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642572680/helios/General/hdg_logo_22_lf1u9b.svg" alt="integration-1" />
                </div>
              </div>
              {/* Image */}
              <div className="brand-image-group--l2__single brand-image-group--l2__img-1 circle-91">
                <div className="brand-logo-1">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_10_oxetej_cfbnco.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-2 circle-103">
                <div className="brand-logo-2">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_09_oisbxe_jymdnj.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-3 circle-98">
                <div className="brand-logo-3">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_08_kfstrx_ldhabu.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-4 circle-99">
                <div className="brand-logo-4">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_07_ve7puh_qcjnuo.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-5 circle-95">
                <div className="brand-logo-5">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_05_wzo8bj_veszdz.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-6 circle-113">
                <div className="brand-logo-6">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_03_zy8wnl_fyz7fs.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-7 circle-88">
                <div className="brand-logo-7">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_02_gdfsio_dvngry.png" alt="integration" />
                </div>
              </div>
              <div className="brand-image-group--l2__single brand-image-group--l2__img-8 circle-108">
                <div className="brand-logo-8">
                  <img className="w-100 img_tech_stack" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641424165/helios/HOME%20HELIOSDG/CARRUSEL_06_yt9yg4_ng1avr.png" alt="integration" />
                </div>
              </div>
              {/* Image Line */}
              <div className="brand-image-group--l2__line-1">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine1} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-2">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine2} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-3">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine3} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-4">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine4} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-5">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine5} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-6">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine6} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-7">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine7} alt="integration" />
              </div>
              <div className="brand-image-group--l2__line-8">
                <img className="w-100" src={Images.projectManagement.BrandLogoLine8} alt="integration" />
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </Integration>
  )
}

export default IntegrationSection
