import React, { useRef } from "react"
import Slider from "react-slick"

export default function SliderBanner() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        arrows: false,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
    };
    return (
        <div className="container_customer">
                <div className="container ">
            {/* <h2 className="typography__Heading-fvecw2-0 style__Title-sc-372oxm-1 hipacl gZXJhI text-center mt-5">Clientes</h2>
            <p className="text-center typography__Paragraph-fvecw2-1 style__Text-sc-372oxm-3 oKLNJ gvgGwQ">Tenemos amplia experiencia en participación con proyectos gubernamentales y <br />desarrollamos soluciones basadas en el uso de tecnologías adhoc para cada proyecto.</p> */}
                <h2 className="helios_ttl text-center">Clientes</h2>
                <p className="helios_txt text-center">Tenemos amplia experiencia en participación con proyectos gubernamentales y <br />desarrollamos soluciones basadas en el uso de tecnologías adhoc para cada proyecto.</p>
            <div className="customer_slider mt-76">

                <Slider {...settings}>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423925/helios/HOME%20HELIOSDG/clientes/CLIENT_WTW_io833m_eat3yo.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423922/helios/HOME%20HELIOSDG/clientes/CLIENT_WB_ponbe3_je7ynr.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423922/helios/HOME%20HELIOSDG/clientes/CLIENT_VW_yxwi7q_imlci9.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_SPOTIFY_kvfkx0_g2eq8c.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_UBER_aislbp_zgjszz.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_UNIVERSAL_uwdviu_vlyogr.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_SHAZAM_hikyqo_iqunmm.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_SAMSUNG_tqpvgp_bxnwfd.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_STARCOM_ckumu5_nmuwps.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_NESCAFE_cwdu94_gxbu9t.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_RUZ_ywvqfh_ypsvtu.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_PEPSI_zbndqp_yu707m.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_NESTLE_fyipy3_crgocw.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_PARAMOUNT_ecwftn_rdvnbt.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423921/helios/HOME%20HELIOSDG/clientes/CLIENT_PGBBQ_mqc2fq_hlgox3.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_NELSON_ps9lzq_ru4lec.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_MEC_lnlay3_tqsznu.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_MAPFRE_trofex_ko6ht0.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_MOVISTAR_svdgyr_w9myzi.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_LINIO_zzkv4m_yceh31.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_LEOBURNETT_bg8qig_vwaeuy.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_KIA_aoaqn4_e4zz2a.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_JJ_kij05y_dc9s2z.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_IPCMEDIABRANDS_qayrpm_hcdfll.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_INFONAVIT_ddzxml_t2foky.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_HONDA_wnepje_seoggd.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_HUAWEI_xwvx1n_teuwm7.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_FONACOT_opybrg_ntj7x7.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_HAVAS_dwv5ca_qxjik1.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_FEMSA_ayab0t_ssa0jb.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423920/helios/HOME%20HELIOSDG/clientes/CLIENT_CRUZROJA_y6rgz4_srlqis.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CMIC_eiwdjk_hzhgox.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CHAMPIONS_sehzmi_l94zdj.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CDMX_vp8qzd_qzvrmb.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CMI_xxdavz_xhfl4q.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CIE_rp6uru_puncp8.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CITIBANAMEX_jlysth_djv3jl.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_BIMBO_a7ds7k_o3jp7h.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_ATT_tz6w6i_kp5wwk.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_CARSO_pqbmei_iljh5m.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1641423919/helios/HOME%20HELIOSDG/clientes/CLIENT_20THFOX_fjbytd_zxabyl.png" />
                    </div>
                   
                </Slider>
            </div>
        </div>
        </div>
        
    )
}