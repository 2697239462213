import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ServicesCard from "./Component/Card"
import Service from "./style"
// import { Productos } from "~data"
//import { GiDeliveryDrone } from "react-icons/gi";

const Productos = {
  services: [
    {
      id: "ss1",
      icon: "fas fa-city",
      title: "Ciudades inteligentes",
      text: "Soluciones basadas en el uso de tecnologías adecuadas para cada proyecto.",
      iconBackground: "#000000",
      linkTo: "ciudades"
    },
    {
      id: "ss2",
      icon: "fas fa-helicopter",
      title: "Drones / ATV / ROV UAV",
      text:
        "Soluciones de desarrollo propio que cubren las operaciones de las diversas áreas de las empresas.",
      iconBackground: "#000000",
      linkTo: "drones-atv-rov-uav"
    },
    {
      id: "ss3",
      icon: "fas fa-shield-alt",
      title: "Ciberseguridad",
      text:
        "Componente esencial de los sistemas necesario en el ADN de cualquier línea de negocio.",
      iconBackground: "#000000",
      linkTo: "ciberseguridad"
    },
    {
      id: "ss4",
      icon: "fas fa-code",
      title: "Desarrollo de software",
      text:
        "Tenemos amplia experiencia en el desarrollo de software para asistir a nuestros clientes.",
      iconBackground: "#000000",
      linkTo: "desarrollo-de-software"
    },
    {
      id: "ss5",
      icon: "fas fa-suitcase",
      title: "Broker de seguros",
      text: "Contamos con asesores especializados que te ayudarán a encontrar el seguro que necesitas.",
      iconBackground: "#000000",
      linkTo: "broker-de-seguros"
    },
    {
      id: "ss6",
      icon: "fas fa-pen-nib",
      title: "Transformación digital",
      text:
        "Integra la tecnología digital en todas las áreas de tu empresa, para brindar valor a sus clientes.",
      iconBackground: "#000000",
      linkTo: "transformacion-digital"
    }
  ]
}

export default function ServicesSection({ ...rest }) {
  return (
    <Service backgroundColor="#F7F7F7" {...rest}>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xxl-6 col-xl-7 col-md-9 col-sm-10">
            <Service.Box className="text-center" mb="30px" mbLG="55px">
              {/* <Service.Subtitle as="h6" fontColor="#ff1e38">
                Our Services
              </Service.Subtitle> */}
              <h2 className="helios_ttl">Soluciones</h2>
            </Service.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
            {Productos.services.map(({ title, icon, text, id, iconBackground, linkTo }) => {
              return (
                <Col
                  xs="12"
                  className="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-xs-10 "
                  key={id}
                >
                <ServicesCard
                  title={title}
                  text={text}
                  icon={icon}
                  to={linkTo}
                  iconBackground={iconBackground}
                />
              </Col>
              )
            })}
        </Row>
      </Container>
    </Service>
  )
}



