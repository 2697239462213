import React from "react"
import { PageWrapper } from "~components/Core"

import FooterOne from "~sections/marketing/FooterOne"

import AboutSection from '../sections/services/About'
// import AboutSectionA from '../sections/agency/About'
import ContentSection from '../sections/agency/ContentOne/ContentSectionOne'
import ContactMap from '../sections/marketing/Contact/Components/Map'
import HeroSection from '~sections/marketing/Herocopy'
import ServicesSection from '~sections/marketing/Services'

// Custom components

import ClientesSlider from "~sections/marketing/Clientes"
import IntegrationSection from "~sections/marketing/Integration"


const header = {
  headerClasses: "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid:true,
  // customLogo: Images.HeaderLogo,
 
}

export default function Marketing() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection />
      <ServicesSection />
      {/* <Products /> */}
      <AboutSection />
      <ContentSection />
     
      <ContactMap />
      <ClientesSlider />
      <IntegrationSection />
      <FooterOne/>
    </PageWrapper>
  )
}
